import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Daajibreadcreumb from "../components/home/daaji-breadcumb";

import Meetdajisubcontent from "../components/home/meet-daaji-content";

// markup
const DaajiPage = () => {
  return (
    <div>
      <Seo title="Meet Daaji" />

      <Layout isSticky>
        <section>
          <Daajibreadcreumb />
        </section>
        <section>
          <Meetdajisubcontent />  
        </section>
      </Layout>
    </div>
  );
};

export default DaajiPage;
