import React from "react";

import spiritualjourney from "../../assets/images/home/spiritual-journey-daaji.jpg";

import kamalshpatel from "../../assets/images/home/kamlesh-d-patel-daaji.png";

export default function Meetdajisubcontent() {
  return (
    <section className="meetdaajicontent-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img
              className="img-fluid mg-bottom20"
              src={spiritualjourney}
              alt="Journey"
            />
          </div>
          <div className="col-md-6">
            <img className="img-fluid" src={kamalshpatel} alt="Journey" />
          </div>
          <div className="col-md-6">
            <p className="theme-p-tag">
              {" "}
              Renowned teacher of meditation, Kamlesh D. Patel, affectionately
              known as Daaji, was born in India. From his young days, he has
              been interested in spirituality and met his Guide, Shri Ram
              Chandra of Shahjahanpur (Babuji), the founder and first President
              of Shri Ram Chandra Mission. Kamlesh continued to follow
              Parthasarathi Rajagopalachari (Chariji), the second President of
              Shri Ram Chandra Mission, after Babuji’s passing away. He is now
              the President of Shri Ram Chandra Mission, teaching Heartfulness
              Meditation.
            </p>
            <p className="theme-p-tag">
              {" "}
              He is a vital role model for anyone wanting to evolve as a human
              being and expand consciousness. A spiritual catalyst, displaying
              the perfect blend of the Eastern heart and Western mind, he is
              able to connect with people from all walks of life and
              backgrounds, giving special attention to today’s youth.
            </p>
            <p className="theme-p-tag">
              {" "}
              To learn more about Daaji, please visit{" "}
              <a
                href="//daaji.org/"
                className="link-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://daaji.org/
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
